import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { linkResolver } from "../components/_helpers/linkResolver"
import { updateImgixParams } from "../components/_helpers/updateImgixParams"

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + "..."
}

const ResourcesPage = () => {
  const data = useStaticQuery(graphql`
    query AllBlogPostsQuery {
      latestPost: allPrismicBlogPost(
        limit: 1
        sort: { order: DESC, fields: data___date }
        filter: { data: { date: { ne: null } } }
      ) {
        nodes {
          type
          uid
          data {
            title {
              html
              text
            }
            cover_image {
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid
              }
            }
            snippet {
              html
              text
            }
            body {
              html
              text
            }
          }
        }
      }

      allPosts: allPrismicBlogPost(
        skip: 1
        sort: { order: DESC, fields: data___date }
        filter: { data: { date: { ne: null } } }
      ) {
        nodes {
          type
          uid
          data {
            title {
              html
              text
            }
            cover_image {
              fixed(width: 450, height: 450) {
                ...GatsbyPrismicImageFixed
              }
            }
            snippet {
              html
              text
            }
            body {
              html
              text
            }
          }
        }
      }
    }
  `)
  if (!data || !data.allPosts.nodes.length) return null

  const latestPost = data.latestPost.nodes[0]
  const allPosts = data.allPosts.nodes

  return (
    <Layout>
      <SEO title="Resources" />

      <section className="resources-hero hero two">
        <div className="row">
          <div className="inner">
            <div
              className="col text center"
              data-sal="slide-up"
              data-sal-delay="0"
            >
              <h3>
                <Link to={linkResolver(latestPost)}>
                  {latestPost.data.title.text}
                </Link>
              </h3>
              <div
                className="snippet"
                dangerouslySetInnerHTML={{
                  __html: latestPost.data.snippet.html,
                }}
              ></div>
              <Link to={linkResolver(latestPost)} className="button violet">
                Read More
              </Link>
            </div>

            <div
              className="col asset"
              data-sal="slide-left"
              data-sal-delay="300"
            >
              <Link to={linkResolver(latestPost)}>
                <Img
                  fluid={latestPost.data.cover_image.fluid}
                  alt={latestPost.data.title.text}
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="row spacer">
          <div className="inner">
            <div className="col"></div>
            <div className="col"></div>
          </div>
        </div>
      </section>

      <section className="resources-posts">
        <div className="inner">
          {allPosts.map((post, ind) => {
            return <Post data={post} ind={ind} key={ind} />
          })}
        </div>
      </section>
    </Layout>
  )
}

export default ResourcesPage

// Partner compontent
const Post = ({ data, ind }) => {
  if (!data) return null
  const post = data.data

  return (
    <div
      className="resource-post"
      data-sal="slide-up"
      data-sal-delay={`${150 + ind++ * 50}`}
    >
      <div className="post">
        <div className="post-image">
          <Link to={linkResolver(data)}>
            <Img
              fixed={updateImgixParams(post.cover_image.fixed, { fit: "crop" })}
              alt={post.title.text}
              imgStyle={{ objectPosition: "center" }}
            />
          </Link>
        </div>

        <div className="post-content">
          <h5>
            <Link to={linkResolver(data)}>{post.title.text}</Link>
          </h5>
          <div
            className="snippet"
            dangerouslySetInnerHTML={{
              __html: truncateString(post.snippet.html, 250),
            }}
          ></div>
          <Link to={linkResolver(data)} className="button violet">
            Read More
          </Link>
        </div>
      </div>
    </div>
  )
}
